<template>
  <v-container
    fluid
    class="mb-9"
  >
    <v-row v-if="aUsers.length > 0">
      <v-col>
        <div v-if="isResponsive">
          <div
            v-for="(item, index) in aUsers"
            :key="index"
            class="card-content mt-4"
          >
            <p style="font-size: 25px;" class="mon-bold mb-0">{{ item.sName }}</p>
            <div class="display-flex align-items-center">
              <p class="mon-medium mb-0">{{ texts.users.table.headers[1] }}</p>
              <v-spacer />
              <p class="mon-regular mb-0">{{ item.sEmail }}</p>
            </div>
            <div class="display-flex align-items-center">
              <p class="mon-medium mb-0">{{ texts.users.table.headers[2] }}</p>
              <v-spacer />
              <p class="mon-regular mb-0">{{ item.sPhoneNumber }}</p>
            </div>
            <div class="display-flex align-items-center">
              <p class="mon-medium mb-0">{{ texts.users.table.headers[3] }}</p>
              <v-spacer />
              <p class="mon-regular mb-0">{{ item.tCreatedAt }}</p>
            </div>
            <div>
              <v-btn
                @click="userDetail(item)"
                class="button-primary mon-bold mt-4"
                style="width: 100%;"
                elevation="0"
              >
                {{
                  (selectLanguage == 'sp')
                  ? 'Detalle'
                  : 'Detail'
                }}
              </v-btn>
            </div>
            <div>
              <v-btn
                @click="userDelete(item.sVendorId)"
                class="button-tertiary mon-bold mt-4"
                style="width: 100%;"
                elevation="0"
              >
                {{
                  (selectLanguage == 'sp')
                  ? 'Eliminar'
                  : 'Delete'
                }}
              </v-btn>
            </div>
          </div>
          <div class="display-flex align-items-center justify-content-center mt-9">
            <div class="pagination-styles">
              <v-pagination
                v-model="iCurrentPage"
                :length="lengthPage"
                color="#FFC556"
                next-icon="mdi-menu-right"
                prev-icon="mdi-menu-left"
              ></v-pagination>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card-content">
            <v-simple-table
              fixed-header
              height="350px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(item, index) in texts.users.table.headers"
                      :key="index"
                      class="text-header-title mon-bold"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in aUsers"
                    :key="index"
                  >
                    <td class="mon-regular border-bottom">{{ item.sName }}</td>
                    <td class="mon-regular border-bottom">{{ item.sEmail }}</td>
                    <td class="mon-regular border-bottom">{{ item.sPhoneNumber }}</td>
                    <td class="mon-regular border-bottom">{{ item.tCreatedAt }}</td>

                    <td
                      class="mon-bold border-bottom"
                      :style="item.bPlatformAccess == false ? { color: '#D8686A' } : { color: '#47B900' } "
                    >
                      {{ 
                      (item.bPlatformAccess == false)
                      ? texts.users.textFalse
                      : texts.users.textTrue
                      }}
                    </td>
                    <td class="mon-regular border-bottom">
                      <div class="display-flex align-items-center">
                        <v-btn
                          @click="userDetail(item)"
                          icon
                        >
                          <v-icon
                            color="#283C4D"
                            size="18px"
                          >
                            mdi-eye
                          </v-icon>
                        </v-btn>
                        <v-btn
                          @click="userDelete(item.sVendorId)"
                          icon
                        >
                          <v-icon
                            color="#D8686A"
                            size="18px"
                          >
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="display-flex align-items-center justify-content-center">
              <div class="pagination-styles">
                <v-pagination
                  v-model="iCurrentPage"
                  :length="lengthPage"
                  color="#FFC556"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <div
          style="height: 500px;"
          class="card-content display-flex align-items-center justify-content-center"
        >
          <p class="empty-text mon-regular">{{ texts.users.textEmpty }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UsersContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aUsers: [],
      lengthPage: 0,
      iCurrentPage: 1,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getUsers();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    userDelete: function (id) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.users.dialogDelete.textTitle,
        api: `${URI}/api/v1/${this.selectLanguage}/users/${id}`,
        redirect: "",
      });
    },
    userDetail: function (item) {
      this.$store.commit("setDialogDetailUser", {
        arr: item,
        active: true,
      });
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "DD/DD/YYYY");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    getUsers: function () {
      const payload = {
        iPageNumber: this.iCurrentPage,
        iItemsPerPage: 10,
        sSearch: this.sSearch,
      };

      if (this.userStatus !== "") {
        payload.bPlatformAccess = this.userStatus;
      }

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/employees`,
        {
          params: payload,
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        }
      )
        .then((response) => {
          this.aUsers = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    userStatus: function () {
      return this.$store.state.userStatus;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getUsers();
      }
    },
    refresh: function () {
      this.getUsers();
    },
    iCurrentPage: function () {
      this.getUsers();
    },
    sSearch: function () {
      this.getUsers();
    },
    userStatus: function () {
      this.getUsers();
    },
  },
};
</script>

<style scoped>
.empty-text {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #6e7d94;
  margin-bottom: 0px !important;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}
</style>